import React, { useState } from 'react';
import Layout from '../../../components/layout';
import PressRegistrationFrom from '../../../components/press-registration-from';
import PressRegistrationContext from '../../../components/press-registration-from/context';
import SEO from '../../../components/seo';

export default function Register(props) {
  const [theUploadedFile, setTheUploadedFile] = useState(null);
  let sharedState = {
    uploadedFile: theUploadedFile,
    setUploadedFileCtxt: setTheUploadedFile,
  };
  return (
    <Layout>
      <SEO lang='en' title='Press Registration | Global Manufacturing & Industrialisation Summit' />
      <PressRegistrationContext.Provider value={sharedState}>
        <PressRegistrationFrom source='Website' page='main' />
      </PressRegistrationContext.Provider>
    </Layout>
  );
}
