import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Typography,
  makeStyles,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  useTheme,
  Link,
} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import PressRegistrationContext from '../context/index';
import { DropzoneDialog, DropzoneArea } from 'material-ui-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import BackupIcon from '@material-ui/icons/Backup';

const useStyles = makeStyles((theme) => ({
  textFieldLabelSpacing: {
    '& .MuiFormLabel-root': {
      marginLeft: '10px',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '0px',
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  dropZone: {
    backgroundColor: '#1B253D',
    minHeight: '20px',
    height: '80px',
  },
  dropZoneParagraph: {
    fontSize: '20px',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

export default function Documents({ errors, touched, values, handleChange, handleBlur, setFieldValue }) {
  const [initFile, setInitFile] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [newArticlesLinks, setNewewArticlesLinks] = useState([]);
  const classes = useStyles();
  const theme = useTheme();

  const uploadedFile = useContext(PressRegistrationContext);
  const handleAddeNewArticlesLinks = (setFieldValue) => {
    values.coverage.push('');
    setNewewArticlesLinks((prevState) => {
      return [...prevState, 'new article'];
    });
  };

  const handleFile = (event, file) => {
    // uploadedFile.setUploadedFileCtxt([file]);
    // setInitFile(file);
  };

  return (
    <Fade bottom>
      <Grid container justify='space-between'>
        <Grid item xs={12}>
          <Box mb={2}>
            {/* <Typography gutterBottom color='textSecondary'>
              Provide Credentials:
            </Typography> */}
            <Typography vairant='body2'>Please provide:</Typography>
            <ul>
              <li>
                <Typography vairant='body2'>Proof of recent work with a legitimate media outlet </Typography>
              </li>
              <li>
                <Typography vairant='body2'>Proof of assignment by legitimate media outlet </Typography>
              </li>
            </ul>
          </Box>
          <Box mb={3}>
            <Box clone width={1} p={2}>
              <TextField
                label='Coverage Article Link'
                // name='coverage'
                className={classes.textFieldLabelSpacing}
                onChange={(e) => {
                  setFieldValue(
                    'coverage',
                    values.coverage.map((_item, index) => {
                      if (index === 0) {
                        return e.target.value;
                      } else return _item;
                    })
                  );
                }}
                value={values.coverage[0]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          {newArticlesLinks && newArticlesLinks.length > 0 && <Typography> Additional Links </Typography>}

          {newArticlesLinks &&
            newArticlesLinks.length > 0 &&
            newArticlesLinks.map((_, idx) => {
              return (
                <Box mb={3} key={idx}>
                  {/* <Box clone width={1} p={2}> */}
                  <Grid container>
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        className={classes.textFieldLabelSpacing}
                        value={values.coverage[idx + 1]}
                        onChange={(e) => {
                          setFieldValue(
                            'coverage',
                            values.coverage.map((_item, index) => {
                              if (index === idx + 1) {
                                return e.target.value;
                              } else return _item;
                            })
                          );
                        }}
                        onBlur={handleBlur}
                        // value={values.coverage[idx]}
                        label={'Additional Link ' + (idx + 1)}
                        name={'coverageArticleLink' + (idx + 1)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <Typography variant='body1'>*</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Box pt={2} textAlign='center'>
                        <Button
                          onClick={() => {
                            setFieldValue(
                              'coverage',
                              values.coverage.filter((_item, index) => {
                                return index !== idx + 1;
                              })
                            );
                            setNewewArticlesLinks(
                              newArticlesLinks.filter((_item, index) => {
                                return index !== idx;
                              })
                            );
                          }}>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* </Box> */}
                </Box>
              );
            })}
          <Button
            disabled={values.coverage[values.coverage.length - 1] !== '' ? false : true}
            color='primary'
            onClick={() => handleAddeNewArticlesLinks(setFieldValue)}>
            + Additional Links
          </Button>
          <Box mt={2}>
            <Typography variant='caption' className={classes.error}>
              {touched.coverage && errors.coverage ? errors.coverage : ''}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* <Box mt={2}>
            {/* <Typography> */}
          <Typography>Please provide:</Typography>
          <ul>
            <li>
              <Typography>Proof of covering GMIS events in the past.</Typography>
            </li>
            <li>
              <Typography>
                Proof of press card, professional credential or other official document or letter to verify your status
                as a journalist
              </Typography>
            </li>
            <li>
              <Typography>Proof of pre-coverage for the upcoming event you are applying for.</Typography>
            </li>
          </ul>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box m={3}>
            <DropzoneArea
              acceptedFiles={['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']}
              maxFileSize={13000000}
              dropzoneClass={classes.dropZone}
              dropzoneParagraphClass={classes.dropZoneParagraph}
              onChange={(files) => {
                setFieldValue('files', files);
              }}
              useChipsForPreview
              previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
              previewText='Selected files'
              showPreviewsInDropzone={false}
              showPreviews
            />
            <Box mt={2}>
              <Typography variant='caption' className={classes.error}>
                {touched.files && errors.files ? errors.files : ''}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant='caption'>
                Maximum number of files: 3<br />
                Maximum file size: 12MB <br />
                Accepted file types: .pdf, .png, .jpeg, .jpg
              </Typography>
            </Box>
            {/* <Box mt={2}>
              {values.files.length > 0
                ? values.files.map((_file, index) => {
                    return (
                      <Typography variant='caption' key={index}>
                        {_file.name}
                        <br />
                      </Typography>
                    );
                  })
                : null}
            </Box> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            To complete your registration, please proceed to Expo’s media accreditation process. Please register for{' '}
            <Link href='https://media.expo2020dubai.com' target='_blank'>
              Tawassul
            </Link>
            , the Expo 2020 Dubai Media Information System. Once approved access to Tawassul, please submit a{' '}
            <Link href='https://media.expo2020dubai.com' target='_blank'>
              Media Accreditation Expression of Interest
            </Link>
            . Expo media accreditation will permit media access to the Expo site and Expo media services and facilities
            while they are attending GMIS.
          </Typography>
        </Grid>

        {/* <Grid item xs={12}>
          <Box width={1} mb={2}>
            <FormControl error={touched.attendingVirtually && errors.attendingVirtually ? true : false}>
              <FormControlLabel
                value={values.attendingVirtually}
                name='attendingVirtually'
                checked={values.attendingVirtually}
                control={<Checkbox onChange={handleChange} />}
                label={<Typography variant='body2'>Tick the box below if you are attending Virtually</Typography>}
              />
              <FormHelperText>
                {touched.attendingVirtually && errors.attendingVirtually ? errors.attendingVirtually : ''}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid> */}
      </Grid>
    </Fade>
  );
}
