import React, { useState, useContext } from 'react';
import { Button, StepLabel, Step, Stepper, Grid, CircularProgress, Typography, Box } from '@material-ui/core';
import PersonalDetailsForm from './steps/personal-information';
import MediaOutlet from './steps/media-outlet';
import Documents from './steps/documents';
import { Formik, Form } from 'formik';
import { navigate } from 'gatsby';
import SectionContainerLayout from '../section-container-layout/section-container-layout';
import axios from 'axios';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import ImageContainer from '../../components/image-container';
import PressRegistrationContext from './context';
const steps = ['Personal Information', 'Proof of Expo 2020 Registration', 'Media Accreditation'];

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const urlRegExp = /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const validationSchema = [
  Yup.object().shape({
    salutation: Yup.object().required('Salutation is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required').email('Invalid email'),
    nationality: Yup.object(),
    country: Yup.object().required('Country is required'),
    mobile: Yup.string().required('Mobile number is required').matches(phoneRegExp, `Invalid mobile number`),
    phone: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
    city: Yup.string(),
  }),

  Yup.object().shape({
    media: Yup.string().required('Media outlet name is required'),
    publishingHouse: Yup.string(),
    address: Yup.string().required('Addresss is required'),
    jobTitle: Yup.string().required('Job Title is required'),
    urlMedia: Yup.string().required('URL of Media Outlet is required'),
    shortBio: Yup.string(),
    linkedIn: Yup.string(),
    twitter: Yup.string(),
    hasMediaAccess: Yup.boolean().required('Please choose one'),
    // referenceCode: Yup.string().when('hasMediaAccess', {
    //   is: true,
    //   then: Yup.string().required('Reference code is required'),
    // }),
    files: Yup.array().when('hasMediaAccess', {
      is: true,
      then: Yup.array().required().min(1, 'Proof of confirmation is required'),
    }),
  }),
  Yup.object().shape({
    files: Yup.array().when('hasMediaAccess', {
      is: false,
      then: Yup.array().required('Credentials are required').min(1, 'Credentials are required'),
    }),
    coverage: Yup.array()
      .required('Coverage Articles are required')
      .min(1)
      .of(Yup.string().required('Coverage Articles are required')),
  }),
];

function getStepContent(step, values, errors, touched, handleChange, handleBlur, setFieldValue) {
  switch (step) {
    case 0:
      return (
        <PersonalDetailsForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      );
    case 1:
      return (
        <MediaOutlet
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      );
    case 2:
      return (
        <Documents
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      );
    default:
      return <div>Not Found</div>;
  }
}

export default function RegistrationFrom(props) {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const uploadedFile = useContext(PressRegistrationContext);

  async function submitForm(values, actions) {
    let formData = new FormData();

    formData.append('categoryCode', 'GUEPRESS');
    formData.append('salutation', values.salutation.name);
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('contact', values.mobile);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('countryCode', values.country.dialCode);
    formData.append('country', values.country.name);
    formData.append('jobTitle', values.jobTitle);
    formData.append('mediaOutletName', values.media);
    formData.append('mediaOutletUrl', values.urlMedia);
    formData.append('address', values.address);

    if (values.nationality.name) formData.append('nationality', values.nationality.name);
    if (values.city && values.city.length > 0) formData.append('city', values.city);
    if (values.publishingHouse && values.publishingHouse.length > 0)
      formData.append('publishingHouse', values.publishingHouse);
    if (values.shortBio && values.shortBio.length > 0) formData.append('shortBio', values.shortBio);
    if (values.linkedIn && values.linkedIn.length > 0) formData.append('linkedin', values.linkedIn);
    if (values.twitter && values.twitter.length > 0) formData.append('twitter', values.twitter);
    // if (values.referenceCode && values.referenceCode.length > 0) formData.append('referenceCode', values.referenceCode);
    for (const _file of values.files) {
      formData.append('files', _file);
    }
    if (!values.hasMediaAccess) {
      formData.append('articleLinks', values.coverage);
    }

    formData.append('fromWebsite', 1);
    // const axiosPost = {
    //   salutation: values.salutation.name,
    //   firstName: values.firstName,
    //   lastName: values.lastName,
    //   email: values.email,
    //   countryCode: values.country.dialCode,
    //   country: values.country.name,
    //   nationality: values.nationality.name,
    //   jobTitle: values.jobTitle,
    //   mobile: values.mobile,
    //   media: values.media,
    //   address: values.address,
    //   idCard: values.idCard,
    //   coverageArticleLink: values.coverageArticleLink,
    //   attendingVirtually: values.attendingVirtually,
    // };
    // console.log('axiosPost', axiosPost);
    // return;
    try {
      //   const PresREGData = {
      //     salutation: values.salutation.name,
      //     firstName: values.firstName,
      //     lastName: values.lastName,
      //     email: values.email,
      //     countryCode: values.country.dialCode,
      //     country: values.country.name,
      //     nationality: values.nationality.name,
      //     jobTitle: values.jobTitle,
      //     mobile: values.mobile,
      //     media: values.media,
      //     address: values.address,
      //     idCard: values.idCard,
      //     coverageArticleLink: values.coverageArticleLink,
      //     attendingVirtually: values.attendingVirtually,
      //   };
      axios
        .post(process.env.GATSBY_EMS_API_URL + 'guests/addFromWebsite', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          actions.setSubmitting(false);
          if (response.status === 200) {
            values.hasMediaAccess
              ? navigate('/press/registration/thankyou/registered')
              : navigate('/press/registration/thankyou');
          }
        })
        .catch((err) => {
          console.log('error', err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  const handleFormikSubmit = (values, actions) => {
    if (isLastStep || values.hasMediaAccess) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <SectionContainerLayout title='PRESS REGISTRATION'>
      <Grid container spacing={3}>
        {props.imageName && (
          <Grid item xs={12} sm={6}>
            <Fade bottom>
              <Box>
                <ImageContainer filename={props.imageName} altText={props.imageAlt || '#GMIS2021'} />
              </Box>
            </Fade>
          </Grid>
        )}
        <Grid item container xs={12} sm={props.imageName ? 6 : 12}>
          <Box width={1}>
            <Box marginLeft='auto' marginRight='auto' width={{ xs: '100%', md: '50%' }} my={7}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <Typography variant='caption'>{label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Box width={1}>
            <Box px={{ xs: 0, md: 10 }}>
              <Formik
                initialValues={{
                  salutation: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  nationality: '',
                  country: '',
                  countryCode: '',
                  city: '',
                  phone: '',
                  mobile: '',
                  media: '',
                  publishingHouse: '',
                  urlMedia: '',
                  shortBio: '',
                  linkedIn: '',
                  twitter: '',
                  address: '',
                  jobTitle: '',
                  files: [],
                  coverage: [''],
                  hasMediaAccess: false,
                  referenceCode: '',
                  //attendingVirtually: false,
                }}
                onSubmit={handleFormikSubmit}
                validationSchema={currentValidationSchema}>
                {({ isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
                  return (
                    <Form autoComplete='off'>
                      {getStepContent(activeStep, values, errors, touched, handleChange, handleBlur, setFieldValue)}
                      <Box mt={5}>
                        <Grid container justify='space-between'>
                          <Box order={{ sm: 2, md: 1 }} clone>
                            <Grid item xs={12} md={1} lg={1}>
                              <Box mt={1}>
                                {activeStep !== 0 && (
                                  <Button fullWidth variant='text' onClick={handleBack}>
                                    Back
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          </Box>
                          <Box order={{ sm: 1, md: 2 }} clone>
                            <Grid item xs={12} md={1} lg={1}>
                              <Box mt={1}>
                                <Button
                                  disabled={isSubmitting}
                                  fullWidth
                                  type='submit'
                                  variant='contained'
                                  color='primary'>
                                  {isLastStep || values.hasMediaAccess ? 'Submit' : 'Next'}
                                </Button>
                                {isSubmitting && <CircularProgress size={24} />}
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </SectionContainerLayout>
  );
}
