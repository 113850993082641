import React from 'react';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  Typography,
  makeStyles,
  FormControlLabel,
  Options,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Link,
} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import { DropzoneArea } from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
  textFieldLabelSpacing: {
    '& .MuiFormLabel-root': {
      marginLeft: '10px',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '0px',
    },
  },
  dropZone: {
    backgroundColor: '#1B253D',
    minHeight: '20px',
    height: '80px',
  },
  dropZoneParagraph: {
    fontSize: '20px',
  },
  error: {
    color: theme.palette.error.main,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

export default function MediaOutlet({ errors, touched, values, handleChange, handleBlur, setFieldValue }) {
  const classes = useStyles();

  return (
    <Fade bottom>
      <Grid container spacing={2} justify='space-between'>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.media && errors.media ? true : false}
                helperText={touched.media && errors.media ? errors.media : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.media}
                label='Media Outlet Name'
                name='media'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.publishingHouse && errors.publishingHouse ? true : false}
                helperText={touched.publishingHouse && errors.publishingHouse ? errors.publishingHouse : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.publishingHouse}
                label='Publishing House'
                name='publishingHouse'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.urlMedia && errors.urlMedia ? true : false}
                helperText={touched.urlMedia && errors.urlMedia ? errors.urlMedia : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.urlMedia}
                label='URL of Meadia Outlet'
                name='urlMedia'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.jobTitle && errors.jobTitle ? true : false}
                helperText={touched.jobTitle && errors.jobTitle ? errors.jobTitle : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.jobTitle}
                label='Job Title'
                name='jobTitle'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.shortBio && errors.shortBio ? true : false}
                helperText={touched.shortBio && errors.shortBio ? errors.shortBio : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shortBio}
                label='Short Bio'
                name='shortBio'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.address && errors.address ? true : false}
                helperText={touched.address && errors.address ? errors.address : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
                label='Address'
                name='address'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.linkedIn && errors.linkedIn ? true : false}
                helperText={touched.linkedIn && errors.linkedIn ? errors.linkedIn : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.linkedIn}
                label='LinkedIn Profile Link'
                name='linkedIn'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.twitter && errors.twitter ? true : false}
                helperText={touched.twitter && errors.twitter ? errors.twitter : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.twitter}
                label='Twitter Handle'
                name='twitter'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <Typography>
                All media attending #GMIS2021 must be registered and accredited for{' '}
                <Link href='https://media.expo2020dubai.com' target='_blank'>
                  Tawassul
                </Link>
                , the Expo 2020 Dubai Media Information System.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <FormControl component='fieldset'>
                <Typography>
                  Have you registered to Expo 2020 and secured a media accreditation via{' '}
                  <Link href='https://media.expo2020dubai.com' target='_blank'>
                    Tawassul
                  </Link>{' '}
                  ?
                </Typography>
                <RadioGroup
                  name='hasMediaAccess'
                  value={values.hasMediaAccess.toString()}
                  onChange={(event) => {
                    setFieldValue('hasMediaAccess', event.currentTarget.value === 'true' ? true : false);
                    setFieldValue('files', []);
                  }}>
                  <FormControlLabel value={'true'} control={<Radio />} label='Yes' />
                  <FormControlLabel value={'false'} control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        {values.hasMediaAccess && (
          <>
            <Grid item xs={12} md={6}>
              <Typography> Upload proof of confirmation: </Typography>
              {/* <Box mb={1}>
              <Box clone width={1} p={2}>
                <TextField
                  // value={props.myvalue}
                  className={classes.textFieldLabelSpacing}
                  error={touched.referenceCode && errors.referenceCode ? true : false}
                  helperText={touched.referenceCode && errors.referenceCode ? errors.referenceCode : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.referenceCode}
                  label='Media Access Permit Reference Code'
                  name='referenceCode'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <Typography variant='body1'>*</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box> */}
              <DropzoneArea
                acceptedFiles={['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']}
                maxFileSize={13000000}
                dropzoneClass={classes.dropZone}
                dropzoneParagraphClass={classes.dropZoneParagraph}
                onChange={(files) => {
                  setFieldValue('files', files);
                }}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewChipProps={{ classes: { root: classes.previewChip } }}
                previewText='Selected files'
                showPreviewsInDropzone={false}
                showPreviews
              />
              <Box mt={2}>
                <Typography variant='caption' className={classes.error}>
                  {touched.files && errors.files ? errors.files : ''}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Fade>
  );
}
