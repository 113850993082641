import React from 'react';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import SearchSelectField from '../../registration-form/inputField/search-select-field';
import RegistrationJson from '../../../data/registration.json';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  textFieldLabelSpacing: {
    '& .MuiFormLabel-root': {
      marginLeft: '10px',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '0px',
    },
  },
}));
export default function PersonalDetailsForm({ errors, touched, values, handleChange, handleBlur }) {
  const classes = useStyles();
  return (
    <Fade bottom>
      <Grid container spacing={2} justify='space-between'>
        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={1}>
              <FormControl error={touched.salutation && errors.salutation ? true : false}>
                <Box clone ml={1}>
                  <InputLabel>Salutation</InputLabel>
                </Box>
                <Select
                  fullWidth
                  value={values.salutation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Salutation'
                  name='salutation'
                  endAdornment={
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  }>
                  {RegistrationJson &&
                    RegistrationJson.salutation &&
                    RegistrationJson.salutation
                      .filter((item) => {
                        return item.name !== 'H.E.' && item.name !== 'H.H.';
                      })
                      .map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                </Select>
                <FormHelperText>{touched.salutation && errors.salutation ? errors.salutation : ''}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                // value={props.myvalue}
                className={classes.textFieldLabelSpacing}
                error={touched.firstName && errors.firstName ? true : false}
                helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                label='First Name'
                name='firstName'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                className={classes.textFieldLabelSpacing}
                error={touched.lastName && errors.lastName ? true : false}
                helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                label='Last Name'
                name='lastName'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={1}>
            <Box clone width={1} p={2}>
              <TextField
                className={classes.textFieldLabelSpacing}
                error={touched.email && errors.email ? true : false}
                helperText={touched.email && errors.email ? errors.email : ''}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                label='Email'
                name='email'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <SearchSelectField name='nationality' label='Nationality' data={RegistrationJson.country} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SearchSelectField
            required={true}
            name='country'
            label='Country Of Residence'
            data={RegistrationJson.country}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            // value={props.myvalue}
            fullWidth
            className={classes.textFieldLabelSpacing}
            error={touched.city && errors.city ? true : false}
            helperText={touched.city && errors.city ? errors.city : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            label='City'
            name='city'
          />
        </Grid>

        <Grid item xs={12} sm={6} container>
          <Grid item xs={4} sm={2}>
            <Box mb={1}>
              <Box clone width={1} p={2}>
                <TextField
                  disabled
                  className={classes.textFieldLabelSpacing}
                  value={values.country && values.country.dialCode ? values.country.dialCode : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Code'
                  name='countryCode'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <Typography variant='body1'>*</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Box clone pl={2}>
            <Grid item xs={8} sm={10}>
              <Box mb={1}>
                <Box clone width={1} p={2}>
                  <TextField
                    className={classes.textFieldLabelSpacing}
                    error={touched.mobile && errors.mobile ? true : false}
                    helperText={touched.mobile && errors.mobile ? errors.mobile : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile}
                    label='Mobile Number'
                    name='mobile'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <Typography variant='body1'>*</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} container>
          <Grid item xs={4} sm={2}>
            <Box mb={1}>
              <Box clone width={1} p={2}>
                <TextField
                  disabled
                  className={classes.textFieldLabelSpacing}
                  value={values.country && values.country.dialCode ? values.country.dialCode : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Code'
                  name='countryCode'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <Typography variant='body1'>*</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Box clone pl={2}>
            <Grid item xs={8} sm={10}>
              <Box mb={1}>
                <Box clone width={1} p={2}>
                  <TextField
                    className={classes.textFieldLabelSpacing}
                    error={touched.phone && errors.phone ? true : false}
                    helperText={touched.phone && errors.phone ? errors.phone : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    label='Phone'
                    name='phone'
                  />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fade>
  );
}
